import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const TiskZFoliePage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Tisk z fólie" />
      <h1>Tisk z fólie</h1>
      <div className="flex justify-center mb-4">
        <StaticImage
          src="../images/tisk-z-folie/ukazky-1.png"
          alt="Tisk z fólie"
        />
      </div>
      <p className="text-justify">
        Tradiční, historická metoda tisku. Dnes již na ústupu, přestávají se
        vyrábět stroje, nabídka materiálů se snižuje. Stále však může nabídnout
        levné a dostatečně kvalitní etikety. Její historické základy přináší
        omezení – tisk se provádí pomocí kovové ( většinou hořčíkové ) raznice.
        Ta se musí vyrobit pro každou variantu etikety. Výroba raznice ( štočku
        ) prodlužuje výrobu a zvyšuje cenu o tyto startovací náklady. Bez výroby
        štočku není možné vytisknout ani jeden kus. Vlastní proces je
        následující – raznice zahřátá na cca 140°C se přes folii s pigmentem
        otiskne na textilní stuhu. Obdobně jako kopírák v psacím stroji, pouze
        za působení vyšší teploty.{" "}
      </p>
      <p className="text-justify">
        Barvivo je obdobně jako u termotransferových etiket „připečeno“ pouze na
        povrchu materiálu. Trvanlivost tisku na oprání a oděr je tedy nižší.
        Výhodou metody je při tisku jednoduchých etiket ve velkých sériích velmi
        příznivá cena a rychlost výroby. Tiskneme oboustranně na povrstvený
        materiál - polyester, bavlnu do barevnosti 5/2. Na polyesterový jemný
        satén, který se ve střihu třepí, pouze jednostranně.
      </p>
      <p className="my-4">Na všechny materiály máme certifikát OEKO-TEX.</p>
      <div className="flex justify-center mb-4">
        <StaticImage
          src="../images/tisk-z-folie/ukazky-2.png"
          alt="Tisk z fólie"
        />
      </div>
      <div className="justify-center flex my-5">
        <table>
          <tr>
            <td className="font-bold pr-10">Minimální množství</td>
            <td>50 kusů</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Podkladový materiál</td>
            <td>
              <ul>
                <li>
                  Polyester bílý (P1), polyester černý (P3), polyester-saten
                  (P2), bavlna natur (P8), polyester žlutý a béžový (P6),
                  polyesterový jemný satén (A5) (tisk jednostranně), samolepící
                  acetát bílý (R1) (tisk jednostranně)
                </li>
                <li>
                  Šířky stuh 10, 15, 20,25, 30, 35, 40, 45, 50 mm (Barevné
                  materiály P6 a P8 nejsou dosupné ve všech výše uvedených
                  šířkách, materiál R1 není v šířce 10mm).
                </li>
                <li>Tisk může být oboustranný, barvy do 5/2</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Barvy</td>
            <td>
              Viz <Link to="/vzornik-barev">vzorník</Link>
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Výška etikety</td>
            <td>Libovolná od 20 mm</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Cena</td>
            <td>
              Cena je závislá na velikosti etikety, barevnosti, druhu materiálu
              a objednaném množství
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Dodací lhůta</td>
            <td>1 týden od potvrzení objednávky</td>
          </tr>
        </table>
      </div>
      <p className="my-4">
        Poptávky a jakékoli dotazy či připomínky ohledně etiket zasílejte na:{" "}
        <a className="font-bold" href="mailto:tistene@etka.cz">
          tistene@etka.cz
        </a>
      </p>
      <div className="flex justify-center">
        <StaticImage
          src="../images/tisk-z-folie/ukazky-3.png"
          alt="Tisk z fólie"
        />
      </div>
    </Layout>
  );
};

export default TiskZFoliePage;
